import Link from 'next/link'
import React from 'react'
import { NewsletterSignup } from './newsletter'

const Banner = () => (
  <div className="px-4 py-2 flex flex-col sm:flex-row space-y-2 sm:space-y-0 place-content-between ">
    <header className="text-darkgray" role="banner">
      <h1 className="font-roboto-condensed text-2xl ">
        <Link href="/">VAST: Visiting Artist &amp; Scholar Talks</Link>
      </h1>
      <h2 className="text-md">
        <a href="https://www.colorado.edu/cmci/dcmp">
          DEPARTMENT OF CRITICAL MEDIA PRACTICES
        </a>
      </h2>
    </header>
    <NewsletterSignup />
  </div>
)

export default Banner
