import React, {
  ReactNode,
  FC
} from 'react'
import Header from './header'
import Banner from './banner'
import Footer from './footer'

export type PageProps = {
  children?: ReactNode
}

const Page: FC<PageProps> = ({
  children
}) => (
  <div>
    <Header />
    <Banner />
    <div className="p-4">{children}</div>
    <Footer />
  </div>

)

export default Page
