import ExportedImage from 'next-image-export-optimizer'
import React from 'react'
import BeBoulder from '../assets/media/be-boulder-black.svg'

const Footer = () => (
  <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row text-gray-700 p-4">
    <div className="flex-grow text-sm">
      <span className='font-bold'>Critical Media Practices</span><br />
      <a href="https://www.colorado.edu/map/?id=336&amp;mrkIid=193861" target="_blank" rel="noreferrer">Stadium</a> 255 (Gate 7)<br />
      2085 Colorado Avenue<br />
      University of Colorado Boulder, UCB 477<br />
      Boulder, CO 80309-315<br />
      303-735-6382
    </div>
    <div className="flex-none text-sm">
      <p><a href="https://www.colorado.edu">
        <ExportedImage className="ucb-footer-be-boulder"
          layout="intrinsic"
          src={BeBoulder}
          alt="Be Boulder."
          width={240}
          height={40}
        />
      </a>
      </p>
      <p><a className="ucb-home-link" href="https://www.colorado.edu">University of Colorado Boulder</a></p><p>© Regents of the University of Colorado</p><p className="ucb-footer-links"><a href="http://www.colorado.edu/about/privacy-statement">Privacy</a> • <a href="http://www.colorado.edu/about/legal-trademarks">Legal &amp; Trademarks</a> • <a href="http://www.colorado.edu/map">Campus Map</a></p></div>
  </div>
)

export default Footer
