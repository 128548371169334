import React, { FC } from 'react'
import { Event } from '../visitors/types'
import Vimeo from '@u-wave/react-vimeo'

export const Recording: FC<Event> = ({
  recording
}) => recording?.url
  ? (<Vimeo video={recording.url}
      // width={'100%'}
      // className='w-full flex flex-row'
      responsive={true}
    />)
  : null
