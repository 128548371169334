import React, { FC } from 'react'
import ExportedImage from 'next-image-export-optimizer'
import { google, office365 } from 'calendar-link'
import Link from 'next/link'
import { Carousel } from './carousel'
import { Recording } from './recording'
import { Logos } from './logos'

import { Visitor, Event } from '../visitors/types'

type CalendarLinksProps = {
  e: Event
}

const Loc: FC<CalendarLinksProps> = ({ e }) => e?.location?.url.trim() === ''
  ? (<div>{e.location?.fullname}</div>)
  : (<div className="">
    <a className="text-linkblue" target='_blank' href={e.location.url} rel="noreferrer">{e.location?.fullname}</a>
  </div>)


const CalendarLinks: FC<CalendarLinksProps & { name: string }> = ({ e, name }) =>
  e.start.getTime() > Date.now() ? (
    <>
      <span className="mx-2 hidden sm:inline">•</span>
      <span className="leading-relaxed text-sm block sm:inline my-2 sm:my-0">
        add to calendar:
        <a
          href={google(Object.assign({}, e, { title: name + ': ' + e.title, description: e.blurb, location: e.location.fullname }))}
          target="_blank"
          rel="noreferrer"
          className="p-2 mx-4 bg-lightblue rounded"
        >
          google
        </a>
        <a
          href={office365(Object.assign({}, e, { title: name + ': ' + e.title, description: e.blurb, location: e.location.fullname }))}
          target="_blank"
          rel="noreferrer"
          className="p-2 mx-1 bg-lightblue rounded"
        >
          outlook
        </a>
      </span>
    </>
  ) : null

export const VisitorMini: FC<Visitor> = ({ name, slug, profile, events }) => {
  const talkEvent = events.find((e) => e.kind === 'talk') || events.find((e) => e.kind === 'workshop')
  const blurb = talkEvent
    ? talkEvent.blurb.split(' ').slice(0, 17).concat(['...']).join(' ')
    : ''

  return (
    <a
      href={'/' + slug}
      className="max-w-xl text-left flex flex-col w-full items-center space-y-4 md:p-4 md:border border-slate-300 rounded-xl"
    >
      <div
        className="rounded-t-xl w-full h-64 shadow-sm bg-cover relative"
        style={{ backgroundImage: `url("${profile.src}")` }}
      >
        <div className="absolute bottom-0 bg-gray-900 text-xs text-blue-700 -mt-2 w-full p-2">
          {talkEvent?.start.toLocaleString()}
        </div>
      </div>

      <div className="w-full flex flex-col justify-between h-auto overflow-auto lg:h-auto">
        <h1 className="text-left text-sm md:text-lg font-bold leading-normal">
          {name}
        </h1>
        <h2 className="text-left text-sm md:text-lg font-bold leading-normal">
          {talkEvent?.title}
        </h2>
        <p className="text-sm">{blurb}</p>
      </div>

      <div className="flex-grow " />
      <button className="transition-all duration-100 text-center p-2 rounded-md text-white w-full bg-gradient-to-r from-blue-700 to-blue-500 hover:shadow-md hover:from-blue-800 hover:to-blue-600">
        More
      </button>
    </a>
  )
}

export const VisitorHorz: FC<Visitor> = ({ name, slug, profile, events }) => {
  const talkEvent = events.find((e) => e.kind === 'talk') || events.find((e) => e.kind === 'workshop')
  return (
    <a
      href={'/' + slug}
      className="h-36 container-sm flex flex-row bg-gray-900 text-gray-100 rounded md:flex-row md:max-w-xl hover:bg-darkgray "
    >
      <div className="relative w-2/5 h-36">
        <ExportedImage
          className="rounded-l h-48 overflow-hidden"
          src={profile.src}
          alt={profile.description}
          placeholder="blur"
          layout="fill"
          objectFit="cover"
          objectPosition={profile.position || 'center'}
          sizes="128px"
        />
      </div>

      <div className="w-3/5 justify-between py-2 px-4 leading-normal w-full space-y-1">
        <div className="text-md text-gray-200">
          {talkEvent?.start.toLocaleDateString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            timeZone: 'America/Denver'
          })}
        </div>
        <div className="text-lg font-bold tracking-tight text-gray-100 ">
          {name}
        </div>
        <div className="font-normal text-gray-200 leading-snug">
          {talkEvent?.title}
        </div>
      </div>
    </a>
  )
}

export const VisitorPage: FC<Visitor> = ({
  name,
  bio,
  bioJSX,
  urls,
  logos = [],
  // slug,
  profile,
  events,
}) => {
  return (
    <div className="space-y-14 max-w-5xl text-darkgray w-full">
      <div>
        <h3 className="font-bold text-2xl tracking-widest">{name}</h3>
        <div>
          <div className="space-y-4">
            <div className="sm:float-right sm:relative w-full sm:w-1/3 sm:ml-4 sm:mb-2">
              <ExportedImage
                className="rounded"
                placeholder="blur"
                src={profile.src}
                alt={profile.description}
                sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              />
              <div className='text-xs text-right'>{profile.alt}</div>
            </div>
            <div className="leading-relaxed">{bioJSX || bio}</div>
            <div className="flex flex-col space-y-1">
              {urls.map((u) => (
                <div key={u} className="rounded text-linkblue">
                  <Link
                    key={u}
                    href={u}
                  >
                    {u}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {events.map((e) => (
        <div key={e.kind + e.title} className="flex flex-col space-y-4">
          <h4 className="leading-relaxed font-bold">
            <span className="uppercase bg-darkgray text-white p-1 pl-2 tracking-wider rounded-l">
              {e.kind} :
            </span>
            <span className="tracking-wider bg-lightgray p-1 pl-2 w-full rounded-r ">
              {e.title} {e.subtitle ? ': ' + e.subtitle : ''}
            </span>
          </h4>
          <div className="">
            <span className="font-bold">
              {e?.start.toLocaleDateString('en-us', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'America/Denver'
              })}
            </span>
            <span className="ml-1">MT</span>
            <CalendarLinks e={e} name={name} />
          </div>
          <Loc e={e} />

          <div className="leading-relaxed">
            <div className="mb-4">{e.blurbJSX || e.blurb}</div>
            <div className="w-full mb-4">
              <Recording {...e} />
            </div>
            <Carousel media={e.media} kind={e.kind} title={e.title} />
          </div>
        </div>
      ))}
      <div className='h-12 sm:h-16 md:h-22' />
      <div>
        <Logos logos={logos} />
      </div>
    </div>
  )
}
