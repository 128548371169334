import React, { FC, useRef, useState, useEffect } from 'react'
import ExportedImage from 'next-image-export-optimizer'
import { Media, EventKind } from '../visitors/types'

export type IconProps = {
  size?: string
  fill?: string
  className?: string
}

export const Clear: FC<IconProps> = ({
  size = '24px',
  fill = 'currentColor',
  className = ''
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size || '24px'} viewBox="0 0 24 24" width={size || '24px'} fill={fill} className={className}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </svg>
)

export const ChevronLeft: FC<IconProps> = ({
  size = '24px',
  fill = 'currentColor',
  className = ''
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} height={size} viewBox="0 0 24 24" width={size} fill={fill}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
  </svg>
)

export const ChevronRight: FC<IconProps> = ({
  size = '24px',
  fill = 'currentColor',
  className = ''
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} height={size} viewBox="0 0 24 24" width={size} fill={fill}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
)

type CarouselProps = {
  media: Array<Media>
  kind: EventKind
  title: string
}

const getAlt = (media: Array<Media>, index: number): string => media[index]
  ? media[index].alt
  : ''

type AltBlockProps = {
  media: Array<Media>
  index: number
}
const AltBlock: FC<AltBlockProps> = ({
  media,
  index

}) => {
  const alt = getAlt(media, index)
  return alt && alt.trim() !== ''
    ? (<div id='carousel-alt' className='flex-grow place-content-center flex bg-opacity-50 bg-black p-2 rounded'><div>{alt}</div></div>)
    : (<div id='carousel-alt' className='flex-shrink '>{alt}</div>)
}

type BigImageProps = {
  media?: Media
  index: number
}
const BigImage: FC<BigImageProps> = ({
  media,
  index
}) => media
    ? (
      <ExportedImage
        key={'big_' + index + media.alt}
        className='rounded'
        src={media.src}
        alt={media.description}
        placeholder='blur'
        layout='fill'
        objectFit='contain'
        objectPosition={'center center'} // always centered
      />
    )
    : null

export const Carousel: FC<CarouselProps> = ({
  media,
  kind
}) => {
  const [index, setIndex] = useState(-1)
  const indexRef = useRef<number>(-1)
  const modalRef = useRef<HTMLDivElement>(null)
  const imagesRef = useRef<HTMLDivElement>(null)
  const leftBut = useRef<HTMLButtonElement>(null)
  const rightBut = useRef<HTMLButtonElement>(null)
  const closeBut = useRef<HTMLButtonElement>(null)

  const indexRight = (length: number) => () => setIndex(i => i >= length - 1
    ? 0
    : i + 1
  )
  const indexLeft = (length: number) => () => setIndex(i => i <= 0
    ? length - 1
    : i - 1
  )
  useEffect(() => {
    if (indexRef.current < 0 && index >= 0 && rightBut.current) {
      // opening the modal
      rightBut.current.focus()
    }
    if (indexRef.current >= 0 && index < 0) {
      if (imagesRef?.current?.childNodes[indexRef.current]) {
        // re-focus the indexed image
        (imagesRef.current.childNodes[indexRef.current] as HTMLButtonElement).focus()
      }
    }
    indexRef.current = index
  }, [index])

  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      const isTabPressed = event.key === 'Tab'

      if (isTabPressed) {
        if (event.shiftKey) { // if shift key pressed for shift + tab combination
          if (document.activeElement === leftBut.current && closeBut.current) {
            closeBut.current.focus() // add focus for the last focusable element
            return event.preventDefault()
          }
          if (document.activeElement === rightBut.current && leftBut.current) {
            leftBut.current.focus() // add focus for the last focusable element
            return event.preventDefault()
          }
          if (document.activeElement === closeBut.current && rightBut.current) {
            rightBut.current.focus() // add focus for the last focusable element
            return event.preventDefault()
          }
        } else { // if tab key is pressed
          if (document.activeElement === leftBut.current && rightBut.current) {
            rightBut.current.focus() // add focus for the last focusable element
            return event.preventDefault()
          }
          if (document.activeElement === rightBut.current && closeBut.current) {
            closeBut.current.focus() // add focus for the last focusable element
            return event.preventDefault()
          }
          if (document.activeElement === closeBut.current && leftBut.current) {
            leftBut.current.focus() // add focus for the last focusable element
            return event.preventDefault()
          }
        }
      }
      if (event.key === 'Escape') {
        // don't use closeModal since it causes a re-run
        // of this effect
        setIndex(-1)
      } else if (event.key === 'ArrowRight') {
        indexRight(media.length)()
      } else if (event.key === 'ArrowLeft') {
        indexLeft(media.length)()
      }
    }
    if (index > -1) {
      window.addEventListener('keydown', escFunction)
    }
    return () => {
      window.removeEventListener('keydown', escFunction)
    }
  }, [media, index])

  return (
    <>
      <div className='w-full flex flex-wrap gap-4' aria-label={`images for ${kind}`} ref={imagesRef}>
        {media.map((m, i) => (
          <button
            key={i + m.alt}
            className='relative w-32 h-32 items-center justify-center rounded cursor-pointer'
            aria-label={'open image'}
            onClick={() => setIndex(i)}
          >
            <ExportedImage
              className='rounded'
              src={m.src}
              alt={m.description}
              placeholder='blur'
              layout='fill'
              objectFit='cover'
              objectPosition={m.position || 'center'}
              sizes='128px'
            />
          </button>
        ))}
      </div>
      <div
        className={'z-10 fixed left-0 top-0 w-full h-full bg-gray-900 ' + (index >= 0 ? '' : 'hidden')}
        role="dialog"
        aria-modal="true"
        ref={modalRef}
      // this seems to help ADA
      // tabIndex={-1}
      >
        <div
          className='fixed z-20 left-0 flex flex-col h-screen'
        >
          <div className='flex-grow' />
          <button
            ref={leftBut}
            className='flex-none text-white p-4 cursor-pointer bg-opacity-50 bg-black rounded-full'
            aria-label="previous image"
            onClick={indexLeft(media.length)}
          ><ChevronLeft size='32px' /></button>
          <div className='flex-grow' />
        </div>
        <BigImage index={index} media={media[index]} />
        <div className='fixed z-20 right-0 flex flex-col h-screen'>
          <div className='flex-grow' />
          <button
            ref={rightBut}
            className='flex-none text-white p-4 cursor-pointer bg-opacity-50 bg-black rounded-full'
            onClick={indexRight(media.length)}
            aria-label="next image"
          ><ChevronRight /></button>
          <div className='flex-grow' />
        </div>
        <button
          ref={closeBut}
          className='fixed z-30 right-0 top-0 text-white p-2 pr-4 cursor-pointer '
          aria-label="close"
          onClick={() => setIndex(-1)}
        ><Clear /></button>
        <div className='fixed z-30 left-0 bottom-0 text-white p-2 pr-4 flex flex-row w-full'>
          <div className='flex-grow' />
          <AltBlock media={media} index={index} />
          <div className='flex-grow' />
        </div>
      </div>
    </>
  )
}
