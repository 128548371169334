import React, { FC } from 'react'

export const NewsletterSignup: FC = () => (
  <form
    className="flex flex-col space-y-1"
    id="sib-form"
    method="POST"
    action="https://6b75ff77.sibforms.com/serve/MUIEANXLoBOWEdFFya5mzg7oUU8yCnyWj2sg-b80hNW-LXWlzlKvJ0QAqDQrCkENwn2fW-RXJRNDOxJk9qPJOV50-VjazQzF1E8fnM37R-q5MT4L6xcsLtYtRV7tfshkEfCb4UjqRRAwtPwzY8bKquZTVp8YbMO00FBYqr25HoQn7tyVAZjJvn46xGBa9HlBQm3OQcLXkjnopZ3h"
  >
    <label className="text-xs md:text-sm" data-required="*">
      Subscribe to our newsletter
    </label>
    <div className="w-full sm:w-auto flex flex-row text-xs md:text-sm">
      <input
        className="rounded-l border border-1 p-2 flex-grow w-48 md:w-64"
        type="text"
        id="EMAIL"
        name="EMAIL"
        placeholder="email address"
        data-required="true"
        required
      />

      <button
        className="bg-gray-900 text-white rounded-r p-2 "
        form="sib-form"
        type="submit"
      >
        SUBSCRIBE
      </button>
    </div>
    <input
      type="text"
      name="email_address_check"
      value=""
      onChange={() => null}
      className="hidden"
    />
    <input type="hidden" name="locale" value="en" onChange={() => null} />
    <input
      type="hidden"
      name="html_type"
      value="simple"
      onChange={() => null}
    />
  </form>
)
