import ExportedImage from 'next-image-export-optimizer'
import React from 'react'
import CULogo from '../assets/media/cu-boulder-logo-text-black.svg'
import Search from '../assets/media/search-black.svg'

const Header = () => (
  <div className="pl-4 pr-4 p-2 flex flex-row bg-white">
    <a href="https://www.colorado.edu" className="flex-grow">
      <ExportedImage
        src={CULogo}
        alt="University of Colorado Boulder"
        layout="intrinsic"
        width={240}
        height={22}
      />
    </a>
    <a href="https://www.colorado.edu/search" className="flex-none">
      <ExportedImage
        src={Search}
        alt="search"
        layout="intrinsic"
        width={22}
        height={22}
      />
    </a>
  </div>
)

export default Header
