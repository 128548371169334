
import React, { FC, ReactNode } from 'react'
import ExportedImage from 'next-image-export-optimizer'

import { LogoNames, allLogos } from '../visitors/types'

import LEAFLogo from '../assets/logos/leaf.png'
import THDNLogo from '../assets/logos/thdn.jpg'
import CHALogo from '../assets/logos/cha.png'
import WFLogo from '../assets/logos/wavefarm-logo.png'
import DCMPLogo from '../assets/logos/dcmp-logo.png'
import ATLASLogo from '../assets/logos/atlas-logo.svg'

import IAWPLogo from '../assets/logos/iawp.svg'
import MALLogo from '../assets/logos/mal-logo.png'
import IMALogo from '../assets/logos/ima_logo_free.png'
import BMKOESLogo from '../assets/logos/BMKOES_Logo_srgb.png'
import CMCILogo from '../assets/logos/CMCI_outlines_richblackgold.svg'
import B2Logo from '../assets/logos/b2_logo.jpg'
import RoserLogo from '../assets/logos/roser_logo.png'
import MissionZeroLogo from '../assets/logos/mission_zero.png'
import CDEMLogo from '../assets/logos/cdem.png'
import MEDlab from '../assets/logos/MEDLabLogo.png'
import LibrariesLogo from '../assets/logos/libraries.png'
import EnglishLogo from '../assets/logos/english.jpg'
import MediaStudiesLogo from '../assets/logos/mediastudies.png'

const classAll = " flex-none place-items-center cursor-pointer flex"
const CDEM = () => (
  <div className={"w-56 " + classAll}><a href={allLogos.cdem.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={CDEMLogo}
      alt='CDEM logo'
      style={{ aspectRatio: 986 / 231 }}
    />
  </a></div>
)

const MissionZero = () => (
  <div className={"w-44 " + classAll} ><a href={allLogos.missionzero.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={MissionZeroLogo}
      alt='Mission Zero logo'
      style={{ aspectRatio: 240 / 63 }}
    />
  </a></div>
)


const Roser = () => (
  <div className={"w-16 " + classAll}><a href={allLogos.roser.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={RoserLogo}
      alt='Roser logo'
      style={{ aspectRatio: 80 / 129 }}
    />
  </a></div>
)

const B2 = () => (
  <div className={"w-40 " + classAll}><a href={allLogos.b2.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={B2Logo}
      alt='B2 logo'
      style={{ aspectRatio: 224 / 68 }}
    />
  </a></div>
)

const IAWP = () => (
  <div className={"w-64 " + classAll}><a href={allLogos.iawp.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={IAWPLogo}
      alt='IAWP logo'
      style={{ aspectRatio: 300 / 29 }}
    />
  </a></div>
)

const MAL = () => (
  <div className={'w-24 ' + classAll}><a href={allLogos.mal.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={MALLogo}
      alt='MAL logo'
      style={{ aspectRatio: 80 / 40 }}
    />
  </a></div>
)

const IMA = () => (
  <div className={"w-24 " + classAll}><a href={allLogos.ima.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={IMALogo}
      alt='IMA logo'
      style={{ aspectRatio: 96 / 35 }}
    />
  </a></div>
)

const BMKOES = () => (
  <div className={"w-52 " + classAll}><a href={allLogos.bmkoes.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={BMKOESLogo}
      alt='BMKOES logo'
      style={{ aspectRatio: 1723 / 463 }}
    />
  </a></div>
)

const CMCI = () => (
  <div className={"w-24 " + classAll}><a href={allLogos.cmci.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={CMCILogo}
      alt='CMCI logo'
      style={{ aspectRatio: 96 / 70 }}
    />
  </a></div>
)

const Wavefarm = () => (
  <div className={'w-24 ' + classAll}><a href={allLogos.wavefarm.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={WFLogo}
      alt='wave farm logo'
      style={{ aspectRatio: 1 }}
    />
  </a></div>
)

const Atlas = () => (
  <div className={'w-28 ' + classAll}><a href={allLogos.atlas.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      alt='atlas logo'
      src={ATLASLogo}
      style={{ aspectRatio: 300 / 64 }}
    />
  </a></div>
)

const DCMP = () => (
  <div className={"w-44 " + classAll}><a href={allLogos.dcmp.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      alt='DCMP logo'
      src={DCMPLogo}
      style={{ aspectRatio: 1036 / 199 }}
    />
  </a></div>
)

const Medlab = () => (
  <div className={"w-36 " + classAll}><a href={allLogos.medlab.url}>
    <ExportedImage
      className="w-full relative"
      alt='medlab logo'
      src={MEDlab}
      style={{ aspectRatio: 2000 / 806 }}
    />
  </a></div>
)

const Libraries = () => (
  <div className={"w-64 " + classAll}><a href={allLogos.libraries.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={LibrariesLogo}
      alt='University Libraries logo'
      style={{ aspectRatio: 575 / 116 }}
    />
  </a></div>
)

const English = () => (
  <div className={"w-36 " + classAll}><a href={allLogos.english.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={EnglishLogo}
      alt='English Department logo'
      style={{ aspectRatio: 288 / 180 }}
    />
  </a></div>
)

const MediaStudies = () => (
  <div className={"w-64 " + classAll}><a href={allLogos.mediastudies.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={MediaStudiesLogo}
      alt='English Department logo'
      style={{ aspectRatio: 1475 / 299 }}
    />
  </a></div>
)

const Cha = () => (
  <div className={"w-36 " + classAll}><a href={allLogos.cha.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={CHALogo}
      alt='English Department logo'
      style={{ aspectRatio: 200 / 90 }}
    />
  </a></div>
)

const Thdn = () => (
  <div className={"w-36 " + classAll}><a href={allLogos.thdn.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={THDNLogo}
      alt='Theater and Dance Department logo'
      style={{ aspectRatio: 260 / 137 }}
    />
  </a></div>
)

const Leaf = () => (
  <div className={"w-36 " + classAll}><a href={allLogos.leaf.url}>
    <ExportedImage
      className="w-full relative"
      draggable={false}
      src={LEAFLogo}
      alt='Lafayette Electronic Arts Festival logo'
      style={{ aspectRatio: 640 / 251 }}
    />
  </a></div>
)

const AllLogoComponents: Record<LogoNames, ReactNode> = {
  leaf: (<Leaf key='leaf' />),
  thdn: (<Thdn key='thdn' />),
  cha: (<Cha key='cha' />),
  atlas: (<Atlas key='atlas' />),
  medlab: (<Medlab key='medlab' />),
  dcmp: (<DCMP key='dcmp' />),
  wavefarm: (<Wavefarm key='wavefarm' />),
  iawp: (<IAWP key='iawp' />),
  english: (<English key='english' />),
  libraries: (<Libraries key='libraries' />),
  mediastudies: (<MediaStudies key='mediastudies' />),
  mal: (<MAL key='mal' />),
  bmkoes: (<BMKOES key='bmkoes' />),
  ima: (<IMA key='ima' />),
  cmci: (<CMCI key='cmci' />),
  b2: (<B2 key='b2' />),
  missionzero: (<MissionZero key='missionzero' />),
  cdem: (<CDEM key='cdem' />),
  roser: (<Roser key='roser' />)
}

export const Logos: FC<{ logos: Array<LogoNames> }> = ({
  logos
}) => logos.length
    ? (
      <>
        <div className="font-bold my-4">Additional support by:</div>
        <div className="flex flex-wrap gap-x-4 gap-y-2 md:gap-x-6 md:gap-y-4 justify-start">
          {logos.map(l => AllLogoComponents[l] || null)}
        </div>
      </>
    )
    : null
